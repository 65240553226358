import React, { useContext } from "react";
import ImageDark from "../assets/images/blockchain-development-dark-image.png";
import ImageLight from "../assets/images/blockchain-development-light-image.png";
import { ThemeContext } from "../context/themeContext";
import { SectionTitle } from "../components";
const BlockchainDevlopment = () => {
  const { theme } = useContext(ThemeContext);

  const dataList = [
    { label: "Blockchain integration", link: "", icon: null },
    {
      label:
        "Dapp development on Solana, Binance smart chain, Ethereum, Avalanche, Tron ",
      link: "",
      icon: null,
    },
    { label: "Defi application development ", link: "", icon: null },
    { label: "Ecosystems", link: "", icon: null },
    { label: " Autonomous ORG's (DAO)", link: "", icon: null },
    { label: "Decentralized Casino games", link: "", icon: null },
    { label: "NFT Marketplace: dummy text ", link: "", icon: null },
    { label: "ICO / ICO with MLM", link: "", icon: null },
    { label: "Launchpads like Pinksale & Dxsale", link: "", icon: null },
    { label: "Crypto Wallet / Exchange", link: "", icon: null },
  ];
  return (
    <div
      className={`blockchain-bg ${theme === "dark" ? "dark" : "light"} py-20 `}
    >
      <div className="container">
        <div className="grid md:grid-cols-2 gap-y-10 items-center">
          <div className="order-2 md:order-1">
            <SectionTitle text="BLOCKCHAIN DEVELOPMENT" center={false} />
            <ul className="mt-10">
              {dataList.map((val, i) => (
                <li
                  className="uppercase p-2 grid grid-flow-col justify-start gap-x-2"
                  key={i}
                >
                  <p>•</p>
                  {val.icon ? val.icon : <></>}
                  <a href={val.link || ""} className=" ">
                    {val.label}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="text-right flex justify-center md:justify-end order-1 md:order-2">
            <img src={theme === "dark" ? ImageDark : ImageLight} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlockchainDevlopment;
