import React from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../context/themeContext";
const NotFound = () => {
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <h1 style={{ color: "#0765E3", fontSize: "15em" }}>404</h1>
      <p
        style={{ fontSize: "2em", color: theme === "dark" ? "white" : "black" }}
      >
        {" "}
        Oops Page Not Found
      </p>
      <button
        onClick={() => navigate("/")}
        className=" max-w-max mt-12 md:mt-0 mx-auto bg-gradient-primary py-2.5 px-6 rounded-md text-white"
      >
        Go back to Dashboard
      </button>
    </div>
  );
};

export default NotFound;
