import Home from "./pages/Home";
import "./App.css";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import Projects from "./pages/Projects";
import { useEffect } from "react";
import NotFound from "./pages/404";
function App() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <div className="App dark:bg-black bg-primary-400 transition-all duration-200 ">
        <Routes>
          <Route index element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/404" element={<NotFound />} />
          <Route path="/index.html" element={<Navigate to="/" replace />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
      </div>
    </>
  );
}

export default App;
