import React from "react";

const Icon3 = () => {
  return (
    <svg
      width="65"
      height="64"
      viewBox="0 0 65 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.1663 26.6669C24.2344 26.6669 21.8337 24.2661 21.8337 21.3343V0H5.83371C2.90071 0 0.5 2.40072 0.5 5.33372V58.6666C0.5 61.5996 2.90071 64.0003 5.83371 64.0003H37.8337C40.7656 64.0003 43.1663 61.5996 43.1663 58.6666V26.6666L27.1663 26.6669ZM24.5 58.6669H19.1663C17.6926 58.6669 16.5 57.4749 16.5 56.0006C16.5 54.5263 17.6925 53.3343 19.1663 53.3343H24.5C25.9743 53.3343 27.1663 54.5263 27.1663 56.0006C27.1663 57.4749 25.9743 58.6669 24.5 58.6669Z"
        fill="black"
      />
      <path
        d="M63.7173 8.78262L56.2686 1.33447L53.4404 4.1659L59.9483 10.6682L53.4433 17.1738L56.2714 20.0008L63.7196 12.5526C64.7604 11.5113 64.7604 9.82048 63.7174 8.78248L63.7173 8.78262Z"
        fill="black"
      />
      <path
        d="M38.2278 4.1631L35.3996 1.33496L27.9494 8.7831C26.9075 9.82496 26.9075 11.5175 27.9494 12.5542L35.3996 20.0012L38.2278 17.1731L31.7199 10.6675L38.2278 4.1631Z"
        fill="black"
      />
      <path
        d="M45.1532 0.000976562H49.1822L46.5176 21.3353H42.4863L45.1532 0.000976562Z"
        fill="black"
      />
    </svg>
  );
};

export default Icon3;
