import React from "react";

const Icon1 = () => {
  return (
    <svg
      width="68"
      height="68"
      viewBox="0 0 68 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.2158 32.8316H36.8397C39.424 32.8316 41.5277 30.7277 41.5277 28.1436C41.5277 25.5677 39.4238 23.4556 36.8397 23.4556H29.2158V32.8316Z"
        fill="#000"
      />
      <path
        d="M36.8397 35.1846H29.2158V44.5523H38.6801C41.2644 44.5523 43.3604 42.4484 43.3604 39.8643C43.3598 37.2806 41.2638 35.1846 38.6801 35.1846H36.8397Z"
        fill="#000"
      />
      <path
        d="M33.9997 0.584473C15.5754 0.584473 0.583984 15.5773 0.583984 34.0088C0.583984 52.4402 15.5754 67.4245 33.9997 67.4245C52.4311 67.4245 67.424 52.4402 67.424 34.0088C67.424 15.5759 52.4311 0.584473 33.9997 0.584473V0.584473ZM33.9997 11.5042C32.9517 11.5042 31.8876 11.5762 30.8396 11.7201L30.5198 9.392C31.68 9.2324 32.8395 9.15204 33.9997 9.15204C47.7117 9.15204 58.8554 20.3038 58.8554 34.0078L56.5032 34.1841L56.511 33.952C56.5116 21.592 46.415 11.5034 33.9996 11.5034L33.9997 11.5042ZM11.504 33.9199V34.0081C11.504 46.4159 21.6 56.5124 34.0083 56.5124C35.0401 56.5124 36.0724 56.4404 37.0881 56.3042L37.4001 58.6324C36.2723 58.7841 35.1445 58.8561 34.0005 58.8561C20.3046 58.8561 9.15193 47.7044 9.15193 34.0076L11.504 33.9199ZM30.6168 21.1035H32.7368V17.9679H35.089V21.104H36.849C40.729 21.104 43.8891 24.272 43.8891 28.1442C43.8891 30.248 42.9611 32.128 41.489 33.4243C43.9851 34.5125 45.7211 36.9925 45.7211 39.8725C45.7211 43.7525 42.5531 46.9126 38.681 46.9126L35.0888 46.9115V50.0476H32.7367V46.9036H29.2166V50.0398H26.8728V46.9036H23.4806V44.5515H26.8807V23.4558H23.4806V21.1036H26.8807V17.9681H29.2244V21.1042L30.6168 21.1035Z"
        fill="#000"
      />
    </svg>
  );
};

export default Icon1;
